.default-form {
    background-color: white;
    padding: 30px;
    border: 1px solid #cfd6e4;
    border-radius: 4px;
    margin-bottom: 25px;
}
.default-form h2 {
    margin-bottom: 35px;
}
.form-field {
    width: 100%;
    margin-bottom: 20px;
}
.form-field .MuiInputBase-input {
    font-family: RalewayRegular !important;
    padding: 10px;
}
.form-field.date .MuiInputBase-input {
    font-family: RalewayBold !important;
}
.form-field .MuiTextField-root {
    width: 100%;
}
.MuiSelect-select {
    font-family: RalewayRegular !important;
    padding: 10px !important;
}
.form-field label {
    margin-top: -5px;
    font-family: RalewayRegular !important;
}
.form-field textarea {
    width: calc(100% - 22px);
    border: 1px solid #cfd6e4;
    border-radius: 4px;
    font-family: RalewayRegular !important;
    padding: 10px !important;
    font-size: 14px;
    resize: none;
    height: 60px;
}
.reset {
    font-size: 14px;
    margin-top: 20px;
    background: none;
    border: 0px;
    color: var(--lightblue);
    font-family: RalewayRegular;
}