.header {
    display: none;
    height: 110px;
    position: fixed;
    z-index: 999;
    padding: 0px 20px 0px 20px;
    background-color: white;
    width: 100%;
    box-shadow: 0 5px 10px -5px rgb(0 0 0 / 10%), 0 5px 5px -5px rgb(0 0 0 / 4%);
}
.header-container {
    max-width: 1310px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.logo {
    padding: 10px 0px;
}
.logo img {
    max-width: 135px;
}
.logo img:hover {
    cursor: pointer;
}
.nav {
    display: none;
}
.nav ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}
.nav ul li {
    display: inline;
    margin-left: 40px;
    padding: 8px;
    position: relative;
}
.nav ul li:hover {
    cursor: pointer;
}
.nav ul li.normal {
    padding-left: 25px;
}
.nav ul li.normal svg {
    position: absolute;
    left: 0px;
    top: 11px;
}
.nav ul li.logout svg {
    right: 0px;
    top: 12px;
    position: absolute;
}
.nav ul li.cta svg {
    position: absolute;
    left: 8px;
    top: 11px;
    /*font-size: 20px;*/
}
.nav ul li.cta {
    font-family: RalewayBold;
    background-color: #b5e4ff;
    color: #14A3F6;
    border-radius: 50px;
    padding-left: 35px;
}
.nav ul li.with-icon {
    padding-right: 25px;
}
.nav ul li.logout {
    margin-left: 50px;
    font-size: 14px;
    font-family: RalewayBold;
}

/* iPad portrait */
@media only screen and (min-width: 768px) {
    .header {
        display: block;
    }
    .nav {
        display: block;
        margin-right: 50px;
    }
}

/*
@media only screen and (max-width: 1225px) {
    .nav ul{
        display: flex;
        width: 750px;
    }
}*/