:root {
	--lightblue: #13A3F6;
    --darkerblue: #008bdc;
}
@font-face {
    font-family: RalewayRegular;
    src: url('../assets/fonts/Raleway-Regular.ttf');
}
@font-face {
    font-family: RalewayBold;
    src: url('../assets/fonts/Raleway-Bold.ttf');
}
@font-face {
    font-family: RalewayLight;
    src: url('../assets/fonts/Raleway-Light.ttf');
}
html, body {
    max-width: 100%;
    overflow-x: hidden;
    font-family: RalewayRegular;
}
h1 {
    margin: 0px;
    font-family: RalewayBold;
    color: var(--lightblue);
}
h2 {
    margin: 0px;
    font-family: RalewayBold;
}
h3 {
    margin: 0px;
    font-family: RalewayBold;
}
input {
    font-family: RalewayRegular;
}
p {
    font-family: RalewayRegular;
}
a {
    font-family: RalewayRegular;
}
li {
    font-family: RalewayRegular;
}
span {
    font-family: RalewayRegular;
}

button:hover {
    cursor: pointer;
    /*opacity: 0.9;
    transition: 0.2s;*/
}
.main {
    background-color: #f5f5f5;
    min-height: 100vh;
    padding-bottom: 100px;
}
.container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0px 20px 0px 20px;
}
.content {
    padding-top: 45px;
    padding-bottom: 45px;
}
.button {
    width: 100%;
    padding: 8px;
    background-color: var(--lightblue);
    color: white;
    font-family: RalewayBold;
    font-weight: bold;
    border: 0px;
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 8px;
}
.submit-login span {
    font-family: RalewayBold;
}
.register {
    margin-top: 35px;
}
.MuiDialogContent-root .default-form {
    width: auto !important;
}
.heading {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.heading h1 {
    max-width: 80%;
}
span.time {
    position: relative;
    font-size: 20px;
    z-index: 99;
}
span.time svg {
    position: absolute;
    left: -30px;
    top: 4px;
}
.days, .days-leave-request {
    background-color: white;
    border: 1px solid #cfd6e4;
    border-radius: 4px;
    position: relative;
    z-index: 10;
}
.days-heading {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px 20px 30px;
    background-color: var(--lightblue);
    color: white;
}
.MuiSelect-select {
    font-family: RalewayRegular !important;
    padding: 10px !important;
}
p.no-options {
    text-align: center;
    margin: 0px;
}
.registration-submit {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.registration-submit input {
    width: 40%;
    font-size: 18px;
    padding: 10px;
    border: 1px solid #c1c1c1;
    border-radius: 4px;
}
/*
.registration-submit button {
    width: 50%;
    padding: 10px;
    background-color: var(--lightblue);
    color: white;
    font-family: RalewayBold;
    border: 0px;
    border-radius: 4px;
    font-size: 14px;
}*/
.registration-submit button:hover {
    cursor: pointer;
    background-color: var(--darkerblue);
}
.skeletons-hours {
    padding: 20px 30px;
}
.skeletons-hours .loader {
    margin: 10px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
.no-registrations {
    font-size: 18px;
    text-align: center;
    margin: 30px 0px;
    color: #8c8c8c;
}
.day-actions {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 10px 30px;
}
.day-actions .action-button {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border: 1px solid var(--lightblue);
    color: var(--lightblue);
    background-color: white;
    font-family: RalewayBold;
    border-radius: 4px;
}
.day-actions .action-button span {
    font-family: RalewayBold;
}
.day-actions .totals {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.day-actions .totals p {
    font-family: RalewayBold;
    font-size: 22px;
    color: var(--lightblue);
}
.day-actions .totals svg {
    font-size: 22px;
    color: white;
}
.Toastify__toast {
    font-family: RalewayBold;
}
#artwork-gemini {
    display: none;
}

.bold{
    font-family: RalewayBold;
}

/* iPad Portrait */
@media only screen and (min-width: 768px) {
    .main {
        padding-top: 100px;
        padding-bottom: 0px;
        min-height: calc(100vh - 120px);
    }
}

/* iPad Landscape */
@media only screen and (min-width: 1024px) {
    .register {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    .column-register {
        width: 27%;
        margin-bottom: 0px;
    }
    .default-form {
        width: 100% !important;
    }
    .days {
        width: 65%;
    }

    .days-leave-request {
        width: 72%;
    }

    #artwork-gemini {
        display: block;
    }
}

/* Algemene css */
.status-filter{
    background-color: white;
    padding: 30px;
    border: 1px solid #cfd6e4;
    border-radius: 4px;
    margin-bottom: 25px;

}

.status-filter h2 {
    margin-bottom: 25px;
}

.status-filter > ul {
    width: 100%;
    padding: 0px;
    margin-top: 0px;
}

.status-filter > ul > li {
    list-style-type: none;
    border: 1px solid #cfd6e4;
    padding: 10px;
    margin-bottom: 8px;
}

.div-link{
    display: inline;
    cursor: pointer;
    color: var(--lightblue);
}

.intern-extern{
    font-family: RalewayBold;
    font-size: 15px;
}

/* Kalender */
    .fc-toolbar-title{
        font-size: 23px !important;
        margin-bottom: -8px !important;
    }

    .fc-header-toolbar{
        margin-bottom: 15px !important;
    }

    .fc-scrollgrid{
        border-radius: 5px;
    }

    .fc-day-past{
        background-color: #F5F5F5;
    }

    .fc-day-past:hover{
        /*cursor: not-allowed*;*/
    }

    .fc-day-today:hover, .fc-day-future:hover{
        cursor: pointer;
    }

    .fc td {
        background-color: white;
    }

    .fc-event-title{ 
        white-space: normal; 
        max-width: 100% !important;
        font-size: 11px;
        font-family: RalewayBold;
    }

    .fc-col-header-cell-cushion{
        color: #110741;
        font-family: 'RalewayBold';
    }

    .fc-day-today > .fc-daygrid-day-frame > .fc-daygrid-week-number, .fc-day-future > .fc-daygrid-day-frame > .fc-daygrid-week-number{
        background: white !important;
    }

    .fc-day-past > .fc-daygrid-day-frame > .fc-daygrid-week-number{
        background: #F5F5F5 !important;
    }

    .fc-daygrid-week-number{
        font-size: 13px !important;
    }

    .fc .fc-scrollgrid-liquid{
        background-color: white;
    }

    .fc-day-today{
        background-color: #e5f6fa !important;
    }

    .fc-today-button{
        background-color: #13A3F6 !important;
        font-size: 12px !important;
        padding: 7px 15px !important;
        opacity: 1 !important;
        border: none !important;
        height: 35px;
    }

    .fc-today-button:disabled{
        background-color: #e5f6fa !important;
    }

    .fc-today-button:focus{
        box-shadow: none !important;
    }

    .fc-today-button:not(:disabled):hover{
        opacity: 0.7 !important;
        transition: 0.2s;
    }

    .fc-prev-button{
        font-size: 13px !important;
        padding: 4px 7px !important;
        border-radius: 5px !important;
        margin-left: 3px !important;
        margin-right: 2px !important;
        height: 35px;
        margin-top: 0px !important;
        border: none !important;
        background-color: #13A3F6 !important;
    }

    .fc-next-button{
        font-size: 13px !important;
        padding: 4px 7px !important;
        border-radius: 5px !important;
        margin-left: 2px !important;
        height: 35px;
        margin-top: 0px !important;
        border: none !important;
        background-color: #13A3F6 !important;
    }

    .fc-prev-button > .fc-icon-chevron-left, .fc-next-button > .fc-icon-chevron-right{
        color: white;
    }

    .fc-prev-button:focus, .fc-next-button:focus{
        box-shadow: none !important;
    }

    .fc-prev-button:hover, .fc-next-button:hover{
        opacity: 0.7 !important;
        transition: 0.2s;
    }

    .fc-daygrid-event-harness{
        margin-top: 1px !important;
    }

    .fc .fc-daygrid-day-number{
        padding: 3px;
        font-size: 12px;
    }