.fullscreen{
	display: flex;
}

.table{
    width: 100%;
    border-collapse: collapse;
    font-size: 18px;
    text-align: left;
    position: relative;
    z-index: 99;
    border-radius: 4px;
    border: 1px solid #cfd6e4;
    background-color: #fff;
}

.table th, .table td {
	font-size: 16px;
	padding: 11px;
}

.table th {
    background-color: var(--lightblue);
    color: white;
    font-family: RalewayBold;
}

.table th .th-content {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: RalewayBold;
}

.tooltip-th{
    background-color: var(--lightblue);
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-family: RalewayBold;
}

.th-checkbox{
	width: 20px;
}

.no-registrations-overview{
	margin-left: 0px;
	text-align: left;
}

.width-50{
	width: 87px;
}

.table tr:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
}

.afgeboekt{
    background-color: #f0f0f0;
}

.uren-status{
	display: flex;
    justify-content: space-between;
    align-items: center; /* Optioneel, zorgt ervoor dat de elementen verticaal gecentreerd zijn */
}

.status-label-overview{
	background-color: var(--lightblue);
    color: white;
    font-size: 13px;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
    z-index: 99;
    margin-bottom: 8px;
    gap: 16px; /* Voegt wat ruimte tussen de dropdowns toe */
}

.dropdown-container {
    flex: 1 1 20%; /* Zorgt ervoor dat de dropdowns gelijke breedte hebben en wrappen wanneer er geen ruimte meer is */
    min-width: 200px; /* Zorgt ervoor dat de dropdowns niet te klein worden */
    max-width: 250px;
    background-color: white;
}

.dropdown-container .MuiFormControl-root {
    width: 100%; /* Zorgt ervoor dat de dropdowns de volledige breedte van de container innemen */
}

.status-counter-overview{
	margin-left: 5px;
}

.reset-filters-container {
    display: flex;
    justify-content: flex-end;
}

.reset-filters-button {
    /*background-color: #f44336;
    color: white;*/
    color: var(--lightblue);
    border: none;
    padding: 10px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
}

.reset-filters-button:hover {
    /*background-color: #d32f2f;*/
    text-decoration: underline;
}

.fixed-bottom-right {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    display: flex;
    gap: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.fixed-bottom-right.show-buttons {
    opacity: 1;
}

.fixed-bottom-right button {
    padding: 8px 24px;
    background-color: #1acb55;
    border: 1px solid grey;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
}